import React from 'react';
import $ from 'jquery';

// auth
import auth from '../common/auth';

// img
import successImg from '../static/img/phone/emailSuccess.png';
import emailSuccess from '../static/img/phone/email.png';

class Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            emailState: true,
            userEmail: '',
            errorInfo: '',
            agreementState: true
        }
        this.onSendEmailFn = this.onSendEmailFn.bind(this);
        this.onClickEmailFn = this.onClickEmailFn.bind(this);
        this.onAgreementFn = this.onAgreementFn.bind(this);
    }
    onSendEmailFn (e) {
        let userEmail = e.target.value;
        this.setState({
            userEmail
        })
    }
    onClickEmailFn () {
        let {userEmail, emailState} = this.state;
        let emails = userEmail.replace(/^\s+|\s+$/g,"");
        if( /^[\w\-\+]+(\.[\w\-\+]+)*@(\w-?)+(\.\w{2,})+$/.test(emails) ) {
            let url = auth.state.getCode + '?email='+userEmail+'&source=yiwanghutong&lang=zh';
            if( window.sessionStorage.getItem("searchUrl") ){
                url = auth.state.getCode + '?email='+userEmail+'&source=yiwanghutong&lang=zh' +  window.sessionStorage.getItem("searchUrl");
            }
            $.ajax({
                url: url,
                type: 'GET',
                success: (res)=>{
                    if( res.status_code === 200 ){
                        this.setState({
                            errorInfo: '',
                            emailState: !emailState,
                        })
                    }else if (res.status_code === 20002) {
                        this.setState({
                            errorInfo: 'The email address is registered',
                        })
                    }
                }
            })
            
        }else{
            this.setState({
                errorInfo: 'Please enter a valid email address', 
            })
        }
    }
    /* 
        修改用户协议，默认是同意，如果拒绝将无法进行下一步
    */
   onAgreementFn (e){
        this.setState({
            agreementState: e.target.checked,
        })
    }
    render(){
        let {emailState, errorInfo, userEmail, agreementState} = this.state;
        return (
            emailState? 
            <div id="emailRegister" className="register .col-xs-12 .col-sm-12 .col-md-12 .col-lg-12 specialWidth90">
                <header>Novice registration</header>
                <div className="content">
                    <div className="width80">
                        <div className="tel">
                            <span>Email
                            {
                                errorInfo !== '' ? 
                                <span className="error"> {errorInfo} </span>:""
                            }  
                            </span>
                            <input type="email" name="email" id="email" placeholder="Please enter your email address" onChange={this.onSendEmailFn}/>
                        </div>
                        <div className="mail">
                            {/*  */}
                            <p className="Agreement clearfix">
                                    <input className="pull-left" type="checkbox" defaultChecked onClick={this.onAgreementFn}/>
                                    <span>I have read and agree to the<a href="https://cloud.socialcommit.com/service/useragreement" target="__black"> "User Agreement" </a>and<a href="https://cloud.socialcommit.com/service/privacypolicy" target="__black"> "Privacy Policy" </a></span>
                            </p>
                            {/* <p onClick={()=>{this.props.onCheckRegisterFn(this.props.RegisterStr)}}>使用手机注册</p> */}
                        </div>
                        <div className='registration'>
                            <span onClick={this.onClickEmailFn}>Sign Up for Free</span>
                        </div>
                    </div>
                </div>
                {/* <div className="next">
                    {
                        agreementState? 
                        <p onClick={this.onClickEmailFn}>下一步</p>
                        :
                        <p>下一步</p>
                    }
                </div> */}
            </div>
            :
            <div id="emailRegister" className="register .col-xs-12 .col-sm-12 .col-md-12 .col-lg-12 specialWidth90" style={{height: '3rem'}}>
                <div className="width80">
                    <div className="imgInfo">
                        <img src={emailSuccess} alt=""/>
                    </div>
                    <ul className="list-unstyled">
                        <li className="sendEmail"><p>A verification email has been sent to your email address</p></li>
                        <li className="userEmail width75"><p>{userEmail}</p></li>
                        <li className="tipEmail"><p>If you have not received the email, please check your spam box and subscription email.</p></li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default Register;
