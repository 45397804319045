const auth = {
    state : {
        // 正式环境
        getCode: 'https://api.onesight.com/api/v3/auth/register',
        ValidationCode: 'https://api.onesight.com/api/v3/auth/register/verify',
        registerInfoURL: 'https://cloud.onesight.com/register-info/phone/'


        // // 测试环境
        // getCode: 'https://publisher.onesight.cn/api/v2/user/register',
        // ValidationCode: 'https://publisher.onesight.cn/api/v2/user/verification/',
        // registerInfoURL: 'https://devlogin.onesight.com/registerinfo/phone/'
    }
}

export default auth;