import React from 'react';
import './App.css';
import PhoneRegister from './Pages/Phone';
import EmailRegister from './Pages/Email';

// img
import xiantiaoxia from './static/img/phone/xiantiaoxia.png';
import logo from './static/img/phone/logo.png';
import png1 from './static/img/M/01.png'; 
import png2 from './static/img/M/02.png'; 
import png3 from './static/img/M/03.png'; 
import png4 from './static/img/M/04.png'; 
import banner from './static/img/M/banner.png'

class App extends React.Component {
    
    constructor(props){
        super(props);
        this.state = {
            checkRegister: { "first": false, "last": false}
        }
        this.onCheckRegisterFn = this.onCheckRegisterFn.bind(this);
    }
    onCheckRegisterFn (state) {
        let { checkRegister } = this.state;
        checkRegister[state] = !checkRegister[state]
        this.setState({
            checkRegister,
        })
    }
    render () {
        let { checkRegister } = this.state;
        return (
            <div className="widthAuto container-fluid">
                <div className="row">
                    <div className="banner .col-xs-12 .col-sm-12 .col-md-12 .col-lg-12">
                        <div className="width80">
                            <div className="logo">
                                <img src={logo} alt="" />
                            </div>
                            <img src={banner} alt=""/>
                            <header>Intelligent Unified Marketing Platform</header>
                            <p>Analytics+Content+Marketing+Service Platform for Global Social Media Management. With data-driven and marketing promotion, we provide social media marketing, content marketing, data management marketing and custom marketing services. Learn more about your audience through data analytics, create content and topics they like, analyze engagement and improve cross-channel promotion to drive growth and intelligently manage your social media marketing!</p>
                            <div className="platform">
                                <span className="iconfont iconffbb"></span>
                                <span className="iconfont iconttww"></span>
                                <span className="iconfont iconinss"></span>
                                <span className="iconfont iconlinkedin"></span>
                                <span className="iconfont iconyoutube1"></span>
                            </div>
                            <a href="https://cloud.socialcommit.com/register/email">Sign Up for Free</a>
                        </div>
                        {/* {
                            checkRegister.first ? 
                            <PhoneRegister  onCheckRegisterFn={this.onCheckRegisterFn} RegisterStr='first' />
                            :
                            <EmailRegister onCheckRegisterFn={this.onCheckRegisterFn} RegisterStr='first' />
                        } */}
                    </div>
                    {/* <div>
                        {
                            checkRegister.first ? 
                            <PhoneRegister  onCheckRegisterFn={this.onCheckRegisterFn} RegisterStr='first' />
                            :
                            <EmailRegister onCheckRegisterFn={this.onCheckRegisterFn} RegisterStr='first' />
                        }
                    </div> */}
                    {/* <div className="downArrow .col-xs-12 .col-sm-12 .col-md-12 .col-lg-12 width80">
                        <img src={xiayin} alt="" />
                    </div> */}
                    {/* <div className="brandOS .col-xs-12 .col-sm-12 .col-md-12 .col-lg-12 width80">
                        <header>注册查看中国出海100强名单！</header>
                        <img src={BrandOS} alt="" />
                    </div> */}
                    <div className="Features .col-xs-12 .col-sm-12 .col-md-12 .col-lg-12 width80">
                        <span className="FeaturesLeft"></span>
                        <span className="RotateBox"></span>
                        <span className="FeaturesStr">Product Highlights</span>
                        <span className="RotateBox"></span>
                        <span className="FeaturesRight"></span>
                    </div>
                    <div className="SocialMedia .col-xs-12 .col-sm-12 .col-md-12 .col-lg-12 width80">
                        <header>
                            <p className="SocialMediaStr">
                                <i className='iconfont iconshuju'></i>Data Analytics and Global Social Media Data Monitoring
                            </p>
                        </header>
                        <ul className="list-unstyled">
                            <li>1、<span>Social Networks Integrations, Powerful Database Support, Comprehensive and Visual Data, Drive Marketing Automation，Five Major Social Media Platforms Support.</span></li>
                            <li>2、<span>With pages compare，global insights, operational issues, precision improvements! Help the company to do everything in its grasp and win in the future!</span></li>
                            <li>3、<span>Paid Social Media，Social Media Monitoring will help you to understand competitors’ strategy. Monitor what people are saying about your brand and inspire new conversations.</span></li>
                        </ul>
                        <img src={png1} alt='' />
                    </div>
                    <div className="RichFunction .col-xs-12 .col-sm-12 .col-md-12 .col-lg-12 width80">
                        <header>
                            <p className="RichFunctionStr"> <i className='iconfont iconneirong'></i> Content Management with Intelligent and Efficient Marketing Assistant</p>
                        </header>
                        <ul className="list-unstyled">
                            <li>1、<span>View the best content in your industry across social media platforms in Inspiration Found. A variety of ideas for you to learn and be inspired!</span></li>
                            <li>2、<span>Social media posts publishing, marketing assistant - New Posting Tool with more data tracking and posts analytics.</span></li>
                            <li>3、<span>Intelligent Content Recommendations<br/>Intelligent content recommendations and content ideas provide you with efficient and accurate materials for your social media posts.</span></li>
                        </ul>
                        <img src={png2} alt='' />
                    </div>
                    <div className="AddTool .col-xs-12 .col-sm-12 .col-md-12 .col-lg-12 width80">
                        <header>
                            <p className="AddToolStr"><i className='iconfont iconyingxiao'></i> Marketing, Accurate Advertising, Effective News Spread</p>
                        </header>
                        <ul className="list-unstyled">
                            <li>1、<span>Intelligent and accurate advertising, with the help of AI Marketing Data and massive volume of contents, help you to create new smart advertising.</span></li>
                            <li>2、<span>Analyze massive data amount to accurately match your advertising to your target audience, thereby bring precise marketing. Bring business growth with real users.</span></li>
                            <li>3、<span>Marketing Calendar<br/>Plan rationally, manage your marketing plans and processes, know marketing highlight, grasp marketing opportunities, boost conversation, and work together with less effort.</span></li>
                        </ul>
                        <img src={png3} alt='' />
                    </div>
                    <div className="UserCare .col-xs-12 .col-sm-12 .col-md-12 .col-lg-12 width80">
                        <header>
                            <p className="UserCareStr"><i className='iconfont iconguanhuai'></i> Client Care and Efficient Solutions Provide</p>
                        </header>
                        <ul className="list-unstyled">
                            <li>1、<span>Customer conversions, tracking customers and reporting your impact on marketing, automatically recording and organizing each interaction between your customers and your brand.</span></li>
                            <li>2、<span>Content marketing, creating a full-content strategy, and getting real-time SEO advices to attract the right influencers and fans.</span></li>
                            <li>3、<span>Email Marketing<br/>Provide a personalized purchase path for each potential customer. Every interaction with your content can automatically trigger a perfect follow-up.</span></li>
                        </ul>
                        <img src={png4} alt='' />
                    </div>
                    
                    <div className="registerBottom">
                        {
                            checkRegister.last ? 
                            <PhoneRegister onCheckRegisterFn={this.onCheckRegisterFn} RegisterStr='last' />
                            :
                            <EmailRegister onCheckRegisterFn={this.onCheckRegisterFn} RegisterStr='last' />
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default App;
