import React from 'react';
import $ from 'jquery';

// auth
import auth from '../common/auth';

class Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userPhone: null, //用户手机号
            userCode: null, //用户验证码
            errorInfo: '',  //错误信息
            sendMessage: false, //是否发送验证码
            countdown: 60,  //倒计时
            agreementState: true, // 用户是否同意协议
        }
        this.onCheckPhoneFn = this.onCheckPhoneFn.bind(this);
        this.onGetPhoneNumFn = this.onGetPhoneNumFn.bind(this);
        this.onValidationCodeFn = this.onValidationCodeFn.bind(this);
        this.countDownFn = this.countDownFn.bind(this);
        this.onAgreementFn = this.onAgreementFn.bind(this);
        this.timer = null;
    }
    onCheckPhoneFn (e,state) {
        let userPhone = e.target.value;
        switch (state) {
            case 'phone':
                this.setState({
                    userPhone,
                    errorInfo:''
                })
                break;
            case 'code':
                    this.setState({
                        userCode: userPhone,
                    })
                    break;
            default:
                break;
        }
    }
    countDownFn(){
        let {countdown} = this.state;
        clearInterval(this.timer)
        this.timer = setInterval(() =>{
            countdown--;
            this.setState({
                countdown,
            })
            if(countdown<=0){
                clearInterval(this.timer);
                this.setState({
                    countdown: null,
                })
            }
        },1000)
    }
    onGetPhoneNumFn () {
        console.log()
        let {userPhone} = this.state;
        let reg = /^1[3-9]\d{9}$/
        if( reg.test(userPhone) ){
            let url = auth.state.getCode + '?phone=%2b86 '+userPhone+'&source=yiwanghutong';
            if( window.sessionStorage.getItem("searchUrl") ){
                url = auth.state.getCode + '?phone=%2b86 '+userPhone+'&source=yiwanghutong' +  window.sessionStorage.getItem("searchUrl");
            }
            $.ajax({
                url: url,
                type: 'GET',
                beforeSend: ()=>{
                    this.setState({
                        errorInfo: '',
                        countdown: 60,
                        sendMessage: true,
                    },this.countDownFn)
                },
                success: (res)=>{
                    if ( res.status_code === 200 ) {
                        // this.setState({
                        //     errorInfo: '',
                        //     countdown: 60,
                        //     sendMessage: true,
                        // },this.countDownFn)
                    }else if ( res.status_code === 20014 ){
                        this.setState({
                            errorInfo: '验证码发送间隔需要一分钟',
                            sendMessage: false,
                        })
                    }else if ( res.status_code === 20015 ) {
                        this.setState({
                            errorInfo: '验证码发送达到上限',
                            sendMessage: false,
                        })
                    }else if ( res.status_code === 20012 ){
                        this.setState({
                            errorInfo: '电话已注册',
                            sendMessage: false,
                        })
                    }
                }
            })
        }else {
            this.setState({
                errorInfo: "请输入有效的中国大陆手机号",
            })
        }
    }
    onValidationCodeFn () {
        let { userPhone, userCode } = this.state;
        if(userPhone !== null && userCode !== ''){
            $.ajax({
                url: auth.state.ValidationCode + '/'+userCode+'?phone=%2b86 '+userPhone,
                type: 'GET',
                success: (res)=>{
                    if(res.status_code === 200) {
                        window.location.href = auth.state.registerInfoURL + '+86 ' + userPhone  + "/" + userCode + "/zh";
                    }
                }
            })
        }else{
            this.setState({
                errorInfo: "请输入有效的中国大陆手机号",
            })
        }
    }
    /* 
        修改用户协议，默认是同意，如果拒绝将无法进行下一步
    */
    onAgreementFn (e){
        this.setState({
            agreementState: e.target.checked,
        })
    }
    render () {
        let {errorInfo, sendMessage, countdown, agreementState} = this.state;
        return (
            <div id="phoneRegister" className="register .col-xs-12 .col-sm-12 .col-md-12 .col-lg-12 specialWidth90">
                <header>Novice registration</header>
                <div className="content">
                    <div className="width80">
                        <div className="tel">
                            <span>手机号
                            {
                                errorInfo ?
                                <span className="error">{errorInfo}</span>:""
                            }
                            </span>
                            <input type="tel" maxLength="11" minLength="0" name="tel" id="tel" placeholder="请输入手机号" onChange={(e)=>{this.onCheckPhoneFn(e,'phone')}}/>
                        </div>
                        <div className="passwd">
                            <span>验证码</span>
                            <div>
                            <input type="tel" maxLength="6" minLength="0" name="number" id="passwd" placeholder="输入验证码"  onChange={(e)=>{this.onCheckPhoneFn(e,'code')}}/>
                            {
                                sendMessage ?
                                    countdown <= 0 ? <button type="button" onClick={this.onGetPhoneNumFn}>重新发送</button>: <button>{countdown}</button>
                                :
                                <button type="button" onClick={this.onGetPhoneNumFn}>发送验证码</button>
                            }
                            </div>
                        </div>
                        <div className="mail">
                            <p className="Agreement clearfix">
                                <input type="checkbox" defaultChecked onClick={this.onAgreementFn}/>
                                <span >我同意<a href="https://login.onesight.com/service/useragreement" target="__black"> 服务协议</a>和<a href="https://login.onesight.com/service/privacypolicy" target="__black"> 隐私条款 </a></span>
                            </p>
                            <p onClick={()=>{this.props.onCheckRegisterFn(this.props.RegisterStr)}}>邮箱注册</p>
                        </div>
                        <div className='registration'>
                        <span onClick={this.onValidationCodeFn}>免费注册</span>
                        </div>
                    </div>
                </div>
                {/* <div className="next">
                    {
                        agreementState? 
                        <p onClick={this.onValidationCodeFn}>下一步</p>
                        :
                        <p>下一步</p>
                    }
                </div> */}
            </div>
        );
    }
}

export default Register;
